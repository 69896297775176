import React, {useEffect, useState} from 'react';
import marketStore from "../../storage/MarketStore";
import {observer} from "mobx-react-lite";
import Select from "../../ui/Select";
import Breadcrumb from '../Category/Breadcrumb'
import Products from "../Category/Products";
import SubCategories from "../Category/SubCategories";
import {useParams} from "react-router";


const Client = () => {
    const args = useParams();
  useEffect(() => {
    marketStore.getCategory(1);
  }, []);
  return (
      <div>
        {/* Breadcrumb */}
        <Breadcrumb/>
        <SubCategories/>
          <ul>
            {Object.entries(args).map(([key, value]) => (
              <li key={key}>
                <strong>{key}</strong>: {value}
              </li>
            ))}
          </ul>
        {/* Filters */}
        {/*<div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between gap-lg-4 mb-2">*/}
        {/*  <div className="overflow-x-auto">*/}
        {/*    <ul className="nav nav-pills flex-nowrap gap-2 text-nowrap pb-3">*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link active" aria-current="page" href="#">All products</a>*/}
        {/*      </li>*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link" href="#">Vectors</a>*/}
        {/*      </li>*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link" href="#">Mockups</a>*/}
        {/*      </li>*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link" href="#">Photos</a>*/}
        {/*      </li>*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link" href="#">3D</a>*/}
        {/*      </li>*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link" href="#">AI images</a>*/}
        {/*      </li>*/}
        {/*      <li className="nav-item">*/}
        {/*        <a className="nav-link" href="#">Templates</a>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*  <div className="d-flex flex-column flex-sm-row gap-sm-2 pb-1 pb-lg-2">*/}
        {/*    <div className="d-flex align-items-center gap-2 pb-sm-2">*/}
        {/*      <div className="fs-sm fw-semibold text-dark-emphasis me-1">Format:</div>*/}
        {/*      <div style={{"width": "170px"}}>*/}
        {/*        <div className="choices" data-type="select-one" tabIndex={0} role="listbox" aria-haspopup="true"*/}
        {/*             aria-expanded="false">*/}
        {/*          <div className="form-select border-0 rounded-0 ps-0"><select*/}
        {/*              className="form-select border-0 rounded-0 ps-0 choices__input" data-select="{*/}
        {/*    &quot;classNames&quot;: {*/}
        {/*      &quot;containerInner&quot;: [&quot;form-select&quot;, &quot;border-0&quot;, &quot;rounded-0&quot;, &quot;ps-0&quot;]*/}
        {/*    },*/}
        {/*    &quot;removeItemButton&quot;: false,*/}
        {/*    &quot;choices&quot;: [*/}
        {/*      {*/}
        {/*        &quot;value&quot;: &quot;All&quot;,*/}
        {/*        &quot;label&quot;: &quot;All formats&quot;*/}
        {/*      },*/}
        {/*      {*/}
        {/*        &quot;value&quot;: &quot;Figma&quot;,*/}
        {/*        &quot;label&quot;: &quot;<div class=\&quot;d-flex align-items-center\&quot;><img src=\&quot;assets/img/home/marketplace/formats/figma.png\&quot; class=\&quot;flex-shrink-0 me-2\&quot; width=\&quot;20\&quot; alt=\&quot;Figma\&quot;> Figma</div>&quot;*/}
        {/*      },*/}
        {/*      {*/}
        {/*        &quot;value&quot;: &quot;Framer&quot;,*/}
        {/*        &quot;label&quot;: &quot;<div class=\&quot;d-flex align-items-center\&quot;><img src=\&quot;assets/img/home/marketplace/formats/framer.png\&quot; class=\&quot;flex-shrink-0 me-2\&quot; width=\&quot;20\&quot; alt=\&quot;Framer\&quot;> Framer</div>&quot;*/}
        {/*      },*/}
        {/*      {*/}
        {/*        &quot;value&quot;: &quot;Illustrator&quot;,*/}
        {/*        &quot;label&quot;: &quot;<div class=\&quot;d-flex align-items-center\&quot;><img src=\&quot;assets/img/home/marketplace/formats/illustrator.png\&quot; class=\&quot;flex-shrink-0 me-2\&quot; width=\&quot;20\&quot; alt=\&quot;Illustrator\&quot;> Illustrator</div>&quot;*/}
        {/*      },*/}
        {/*      {*/}
        {/*        &quot;value&quot;: &quot;Webflow&quot;,*/}
        {/*        &quot;label&quot;: &quot;<div class=\&quot;d-flex align-items-center\&quot;><img src=\&quot;assets/img/home/marketplace/formats/webflow.png\&quot; class=\&quot;flex-shrink-0 me-2\&quot; width=\&quot;20\&quot; alt=\&quot;Webflow\&quot;> Webflow</div>&quot;*/}
        {/*      },*/}
        {/*      {*/}
        {/*        &quot;value&quot;: &quot;Photoshop&quot;,*/}
        {/*        &quot;label&quot;: &quot;<div class=\&quot;d-flex align-items-center\&quot;><img src=\&quot;assets/img/home/marketplace/formats/photoshop.png\&quot; class=\&quot;flex-shrink-0 me-2\&quot; width=\&quot;20\&quot; alt=\&quot;Photoshop\&quot;> Photoshop</div>&quot;*/}
        {/*      }*/}
        {/*    ]*/}
        {/*  }" data-select-template="true" aria-label="Select format" hidden tabIndex={-1} data-choice="active">*/}
        {/*            <option value="All" selected>All formats</option>*/}
        {/*            <option value="Figma">&lt;div class="d-flex align-items-center"&gt;&lt;img*/}
        {/*              src="assets/img/home/marketplace/formats/figma.png" class="flex-shrink-0 me-2" width="20"*/}
        {/*              alt="Figma"&gt; Figma&lt;/div&gt;</option>*/}
        {/*            <option value="Framer">&lt;div class="d-flex align-items-center"&gt;&lt;img*/}
        {/*              src="assets/img/home/marketplace/formats/framer.png" class="flex-shrink-0 me-2" width="20"*/}
        {/*              alt="Framer"&gt; Framer&lt;/div&gt;</option>*/}
        {/*            <option value="Illustrator">&lt;div class="d-flex align-items-center"&gt;&lt;img*/}
        {/*              src="assets/img/home/marketplace/formats/illustrator.png" class="flex-shrink-0 me-2" width="20"*/}
        {/*              alt="Illustrator"&gt; Illustrator&lt;/div&gt;</option>*/}
        {/*            <option value="Webflow">&lt;div class="d-flex align-items-center"&gt;&lt;img*/}
        {/*              src="assets/img/home/marketplace/formats/webflow.png" class="flex-shrink-0 me-2" width="20"*/}
        {/*              alt="Webflow"&gt; Webflow&lt;/div&gt;</option>*/}
        {/*            <option value="Photoshop">&lt;div class="d-flex align-items-center"&gt;&lt;img*/}
        {/*              src="assets/img/home/marketplace/formats/photoshop.png" class="flex-shrink-0 me-2" width="20"*/}
        {/*              alt="Photoshop"&gt; Photoshop&lt;/div&gt;</option>*/}
        {/*          </select>*/}
        {/*            <div className="choices__list choices__list--single">*/}
        {/*              <div className="choices__item choices__item--selectable " data-item data-id={1} data-value="All"*/}
        {/*                   aria-selected="true">*/}
        {/*                All formats*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className="choices__list choices__list--dropdown" aria-expanded="false">*/}
        {/*            <div className="choices__list" role="listbox">*/}
        {/*              <div className="choices__item choices__item--choice choices__item--selectable is-highlighted"*/}
        {/*                   data-select-text data-choice data-choice-selectable data-id={1} data-value="All"*/}
        {/*                   role="option" aria-selected="true">*/}
        {/*                <div>*/}
        {/*                  All formats*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*              <div className="choices__item choices__item--choice choices__item--selectable " data-select-text*/}
        {/*                   data-choice data-choice-selectable data-id={2} data-value="Figma" role="option">*/}
        {/*                <div>*/}
        {/*                  <div className="d-flex align-items-center"><img*/}
        {/*                      src="assets/img/home/marketplace/formats/figma.png" className="flex-shrink-0 me-2"*/}
        {/*                      width={20} alt="Figma"/> Figma*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*              <div className="choices__item choices__item--choice choices__item--selectable " data-select-text*/}
        {/*                   data-choice data-choice-selectable data-id={3} data-value="Framer" role="option">*/}
        {/*                <div>*/}
        {/*                  <div className="d-flex align-items-center"><img*/}
        {/*                      src="assets/img/home/marketplace/formats/framer.png" className="flex-shrink-0 me-2"*/}
        {/*                      width={20} alt="Framer"/> Framer*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*              <div className="choices__item choices__item--choice choices__item--selectable " data-select-text*/}
        {/*                   data-choice data-choice-selectable data-id={4} data-value="Illustrator" role="option">*/}
        {/*                <div>*/}
        {/*                  <div className="d-flex align-items-center"><img*/}
        {/*                      src="assets/img/home/marketplace/formats/illustrator.png" className="flex-shrink-0 me-2"*/}
        {/*                      width={20} alt="Illustrator"/> Illustrator*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*              <div className="choices__item choices__item--choice choices__item--selectable " data-select-text*/}
        {/*                   data-choice data-choice-selectable data-id={5} data-value="Webflow" role="option">*/}
        {/*                <div>*/}
        {/*                  <div className="d-flex align-items-center"><img*/}
        {/*                      src="assets/img/home/marketplace/formats/webflow.png" className="flex-shrink-0 me-2"*/}
        {/*                      width={20} alt="Webflow"/> Webflow*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*              <div className="choices__item choices__item--choice choices__item--selectable " data-select-text*/}
        {/*                   data-choice data-choice-selectable data-id={6} data-value="Photoshop" role="option">*/}
        {/*                <div>*/}
        {/*                  <div className="d-flex align-items-center"><img*/}
        {/*                      src="assets/img/home/marketplace/formats/photoshop.png" className="flex-shrink-0 me-2"*/}
        {/*                      width={20} alt="Photoshop"/> Photoshop*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="d-flex align-items-center gap-2 pb-2">*/}
        {/*      <div className="fs-sm fw-semibold text-dark-emphasis text-nowrap me-1">Sort by:</div>*/}
        {/*      <div style={{"width": "150px"}}>*/}
        {/*        <div className="choices" data-type="select-one" tabIndex={0} role="listbox" aria-haspopup="true"*/}
        {/*             aria-expanded="false">*/}
        {/*          <div className="form-select border-0 rounded-0 ps-0"><select*/}
        {/*              className="form-select border-0 rounded-0 ps-0 choices__input" data-select="{*/}
        {/*    &quot;classNames&quot;: {*/}
        {/*      &quot;containerInner&quot;: [&quot;form-select&quot;, &quot;border-0&quot;, &quot;rounded-0&quot;, &quot;ps-0&quot;]*/}
        {/*    },*/}
        {/*    &quot;removeItemButton&quot;: false*/}
        {/*  }" aria-label="Sort" hidden tabIndex={-1} data-choice="active">*/}
        {/*            <option value="Best sellers" selected>Best sellers</option>*/}
        {/*            <option value="Top rated">Top rated</option>*/}
        {/*            <option value="Newest">Newest</option>*/}
        {/*          </select>*/}
        {/*            <div className="choices__list choices__list--single">*/}
        {/*              <div className="choices__item choices__item--selectable" data-item data-id={1}*/}
        {/*                   data-value="Best sellers" aria-selected="true" role="option">Best sellers*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className="choices__list choices__list--dropdown" aria-expanded="false">*/}
        {/*            <div className="choices__list" role="listbox">*/}
        {/*              <div id="choices--vy9q-item-choice-1"*/}
        {/*                   className="choices__item choices__item--choice is-selected choices__item--selectable is-highlighted"*/}
        {/*                   role="option" data-choice data-id={1} data-value="Best sellers" data-choice-selectable*/}
        {/*                   aria-selected="true">Best sellers*/}
        {/*              </div>*/}
        {/*              <div id="choices--vy9q-item-choice-2"*/}
        {/*                   className="choices__item choices__item--choice choices__item--selectable" role="option"*/}
        {/*                   data-choice data-id={2} data-value="Top rated" data-choice-selectable>Top rated*/}
        {/*              </div>*/}
        {/*              <div id="choices--vy9q-item-choice-3"*/}
        {/*                   className="choices__item choices__item--choice choices__item--selectable" role="option"*/}
        {/*                   data-choice data-id={3} data-value="Newest" data-choice-selectable>Newest*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Products />
      </div>
  );
}

export default observer(Client);